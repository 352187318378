<template>
  <app-page :head="data.head" :body="data.body" />
</template>

<script setup>
const { params, query } = useRoute();

const preview = query.preview ? `&preview=${query.preview}` : "";
const { data } = await useFetch(
  `/api/page_news?lng=${params.locale}&slug=${params.id}${preview}`
);
if (data.value.status === "preview-error") {
  throw createError({
    statusCode: 404,
    data: {
      customMessage: data.value.error,
    },
  });
}
</script>
